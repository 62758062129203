import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader, bgStarColor }) => {
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="w-[90%] ml-auto h-full flex flex-col justify-center items-start relative overflow-hidden">
        <h1 className="text-white">{headertitle}</h1>
        {
          headertitle === Subheader ?
            <h6 className="text-white">Home - {Subheader}</h6>
            :
            <h6 className="text-white">{Subheader}</h6>
        }
        <div className={`svgStar absolute -bottom-28 -right-24 md:-bottom-40 md:-right-40 ${bgStarColor ? bgStarColor : 'bg-white '}  w-[300px] md:w-[500px] h-[300px] md:h-[500px]`}></div>

      </div>
    </div>
  );
};

export default TransparentHeader;
